import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    debtClock: {
      debtClockFederal: `CANADA'S FEDERAL DEBT`,
      debtClockShare: 'YOUR SHARE',
      signPetitionNotBold: 'Sign the petition at ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: 'PROUDLY SUPPORTED BY',
    },
    proudlySupportedBy: {
      supportedBy: `CANADA'S NATIONAL DEBT CLOCK PROUDLY SUPPORTED BY`,
    },
    provinces: {
      bc: `British Columbia's Provincial Debt`,
      ab: `Alberta's Provincial Debt`,
      sk: `Saskatchewan's Provincial Debt`,
      mb: `Manitoba's Provincial Debt`,
      on: `Ontario's Provincial Debt`,
      qc: `Québec's Provincial Debt`,
      nb: `New Brunswick's Provincial Debt`,
      ns: `Nova Scotia's Provincial Debt`,
      nl: `Newfoundland and Labrador's Provincial Debt`,
      pe: `Prince Edward Island's Provincial Debt`,
    },
  },
  fr: {
    debtClock: {
      debtClockFederal: 'DETTE FÉDÉRALE DU CANADA',
      debtClockShare: 'VOTRE PART',
      signPetitionNotBold: 'Signez la pétition au ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: 'FIÈREMENT SOUTENU PAR',
    },
    proudlySupportedBy: {
      // TODO: Change once translation received
      supportedBy: `L'HORLOGE NATIONALE DE LA DETTE DU CANADA FIÈREMENT APPUYÉE PAR`,
    },
    provinces: {
      bc: 'Dette de la Colombie-Britannique',
      ab: `Dette de l'Alberta`,
      sk: 'Dette de la Saskatchewan',
      mb: 'Dette du Manitoba',
      on: `Dette de l'Ontario`,
      qc: 'Dette du Québec',
      nb: 'Dette du Nouveau-Brunswick',
      ns: 'Dette de la Nouvelle-Écosse',
      nl: 'Dette de Terre-Neuve-et-Labrador',
      pe: `Dette de l'Île-du-Prince-Édouard`,
    },
  },
  cn: {
    debtClock: {
      // Mandarin
      debtClockFederal: `加拿大联邦债务`,
      debtClockShare: '您的份额',
      signPetitionNotBold: '在请愿书上签名 ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: '支持源自',
    },
    proudlySupportedBy: {
      supportedBy: `加拿大国债钟支持源自`,
    },
    provinces: {
      bc: '不列颠哥伦比亚省的省级债务',
      ab: '阿尔伯塔省的省级债务',
      sk: '萨斯喀彻温省的省级债务',
      mb: '曼尼托巴省的省级债务',
      on: '安大略省的省级债务',
      qc: '魁北克省的省级债务',
      nb: '新不伦瑞克省的省级债务',
      ns: '新斯科舍省的省级债务',
      nl: '纽芬兰与拉布拉多省的省级债务',
      pe: '爱德华王子岛省的省级债务',
    },
  },
  cn_yue: {
    debtClock: {
      // Cantonese
      debtClockFederal: `加拿大聯邦債務`,
      debtClockShare: '你的份額',
      signPetitionNotBold: '在請願書上簽署 ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: '支持源自',
    },
    proudlySupportedBy: {
      supportedBy: `加拿大國債鐘支持源自`,
    },
    provinces: {
      bc: '不列顛哥倫比亞省的省級債務',
      ab: '艾伯塔省的省級債務',
      sk: '薩斯喀徹溫省的省級債務',
      mb: '曼尼托巴省的省級債務',
      on: '安大略省的省級債務',
      qc: '魁北克省的省級債務',
      nb: '新布倫瑞克省的省級債務',
      ns: '新斯科舍省的省級債務',
      nl: '紐芬蘭與拉布拉多省的省級債務',
      pe: '愛德華王子島省的省級債務',
    },
  },
  tl: {
    debtClock: {
      // Tagalog/Filipino
      debtClockFederal: `Utang Pederal ng Canada`,
      debtClockShare: 'Ang pamamahagi mo',
      signPetitionNotBold: 'Pirmahan ang petisyon sa ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: 'Marangal na sinusuporta ng',
    },
    proudlySupportedBy: {
      supportedBy: `Orasan ng Utang Pederal ng Canada ay Marangal na Sinusuporta ng`,
    },
    provinces: {
      bc: 'Utang ng Lalawigan ng British Columbia',
      ab: 'Utang ng Lalawigan ng Alberta',
      sk: 'Utang ng Lalawigan ng Saskatchewan',
      mb: 'Utang ng Lalawigan ng Manitoba',
      on: 'Utang ng Lalawigan ng Ontario',
      qc: 'Utang ng Lalawigan ng Quebec',
      nb: 'Utang ng Lalawigan ng New Brunswick',
      ns: 'Utang ng Lalawigan ng Nova Scotia',
      nl: 'Utang ng Lalawigan ng Newfoundland and Labrador',
      pe: 'Utang ng Lalawigan ng Prince Edward Island',
    },
  },
  pa: {
    debtClock: {
      // Punjabi
      debtClockFederal: `ਕੈਨੇਡਾ ਦਾ ਫੈਡਰਲ ਕਰਜ਼ਾ`,
      debtClockShare: 'ਤੁਹਾਡਾ ਹਿੱਸਾ',
      signPetitionNotBold: 'ਪਟੀਸ਼ਨ ਉੱਤੇ ਇੱਥੇ ਦਸਤਖਤ ਕਰੋ ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: 'ਮਾਣ ਨਾਲ ਸਹਿਯੋਗ ਪ੍ਰਾਪਤ',
    },
    proudlySupportedBy: {
      supportedBy: `ਕੈਨੇਡਾ ਦੀ ਰਾਸ਼ਟਰੀ ਕਰਜ਼ਾ ਘੜੀ (Canada's National Debt Clock) ਨੂੰ ਇਸ ਤੋਂ ਮਾਣ ਨਾਲ ਸਹਿਯੋਗ ਪ੍ਰਾਪਤ ਹੈ`,
    },
    provinces: {
      bc: 'ਬ੍ਰਿਟਿਸ਼ ਕੋਲੰਬੀਆ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
      ab: 'ਐਲਬਰਟਾ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
      sk: 'ਸਸਕੈਚਵਨ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
      mb: 'ਮੈਨੀਟੋਬਾ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
      on: 'ਓਂਟਾਰੀਓ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
      qc: 'ਕਿਊਬੈੱਕ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
      nb: 'ਨਿਊ ਬਰਨਸਵਿੱਕ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
      ns: 'ਨੋਵਾ ਸਕੋਸ਼ੀਆ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
      nl: 'ਨਿਊਫਾਉਂਡਲੈਂਟ ਅਤੇ ਲੈਬਰਾਡੋਰ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
      pe: 'ਪ੍ਰਿੰਸ ਐਡਵਰਡ ਟਾਪੂ ਦਾ ਸੂਬਾਈ ਕਰਜਾ',
    },
  },
});

export default strings;
