import React, { useEffect, useState } from 'react';
import { IMAGES_URL, UPDATE_INTERVAL } from 'config/constants';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import strings from 'config/strings';
import { selectLogo, getConfig } from 'support/utils';
import { Carousel } from 'react-responsive-carousel';
import './ProudlySupportedBy.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function ProudlySupportedBy() {
  const { language } = useParams();
  strings.setLanguage(language);
  const [images, setImages] = useState([]);

  async function getAndSetImages() {
    const config = await getConfig();
    const { supportedByImages } = config;
    const imagesFetched = supportedByImages;
    setImages(imagesFetched);
  }

  // set images once initially
  useEffect(() => {
    getAndSetImages();
  }, []);

  // get/set images every 30 minutes
  useEffect(() => {
    const updateImages = setInterval(() => {
      getAndSetImages();
    }, UPDATE_INTERVAL);
    return () => {
      clearInterval(updateImages);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        data-testid="proudly-supported-by-text"
        className={classNames(
          `proudly-supported-by-text`,
          `proudly-supported-by-text-${language}`,
        )}
      >
        {strings.proudlySupportedBy.supportedBy.toUpperCase()}
      </div>
      <div className="proudly-supported-by-container">
        <Carousel
          autoPlay={images.length}
          interval={5000}
          infiniteLoop
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          centerMode
          centerSlidePercentage={100}
          stopOnHover={false}
          data-testid="proudly-supported-by-carousel"
        >
          <div className="proudly-supported-by-image">
            <img
              src={selectLogo(language)}
              alt="tax payer logo"
              className="proudly-supported-by-image-width"
            />
          </div>
          {images.length > 0 &&
            images.map(image => {
              return (
                <div key={image} className="proudly-supported-by-image">
                  <img
                    src={`${IMAGES_URL}${image}`}
                    alt="sponsor"
                    className="proudly-supported-by-image-width"
                  />
                </div>
              );
            })}
        </Carousel>
      </div>
    </React.Fragment>
  );
}

export default ProudlySupportedBy;
