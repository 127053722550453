import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from 'components/pages/home/Home';
import ProudlySupportedBy from 'components/pages/ProudlySupportedBy/ProudlySupportedBy';
import './App.css';

function App() {
  return (
    <div data-testid="app" className="App">
      <Switch>
        <Route exact path="/">
          <Redirect to="/clock/en" />
        </Route>
        <Route exact path="/supported-by">
          <Redirect to="/supported-by/en" />
        </Route>
        <Route path="/clock/:language/:province?">
          <Home />
        </Route>
        <Route path="/supported-by/:language/">
          <ProudlySupportedBy />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
