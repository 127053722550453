import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import DebtClockCSS from 'components/common/DebtClockCSS';
import SlidingAds from 'components/pages/home/SlidingAds';
import strings from 'config/strings';
import {
  ProvinceEnum,
  calculateFederalStartValueAndIncrementRate,
  calculateShareStartValueAndIncrementRate,
  getConfig,
  provincialDisplay,
  selectLogo,
} from 'support/utils';
import { UPDATE_INTERVAL } from 'config/constants';
import './Home.css';

function Home() {
  const [debtConfigState, setDebtConfigState] = useState({
    debtFederal: null,
    incrementRateFederal: null,
    debtShare: null,
    incrementRateShare: null,
    population: null,
    startDebt: null,
    endDebt: null,
    startDate: null,
    endDate: null,
    ads: null,
  });
  const { language, province } = useParams();

  // used to compute startValue & incrementRate for debtShare and federalDebt, and some animation intervals
  // could be possible to set different values for federalInterval depending on the difference in magnitude between startDebt and endDebt
  const federalInterval = 1001;
  const shareInterval = 1000000;

  async function setDebtConfig() {
    const debtConfig = await getConfig();

    const { startDate, endDate, ads } = debtConfig;

    let population;
    let startDebt;
    let endDebt;
    if (provincialDisplay(province)) {
      population = debtConfig.population[province];
      startDebt = debtConfig.debt[province].startDebt;
      endDebt = debtConfig.debt[province].endDebt;
    } else {
      population = debtConfig.population.national;
      startDebt = debtConfig.debt.national.startDebt;
      endDebt = debtConfig.debt.national.endDebt;
    }

    const debtConfigFederal = calculateFederalStartValueAndIncrementRate(
      startDebt,
      endDebt,
      startDate,
      endDate,
      federalInterval,
      population,
    );
    const debtConfigShare = calculateShareStartValueAndIncrementRate(
      startDebt,
      endDebt,
      startDate,
      endDate,
      shareInterval,
      population,
    );
    setDebtConfigState({
      ...debtConfigState,
      debtFederal: debtConfigFederal.startValue,
      incrementRateFederal: debtConfigFederal.incrementRate,
      debtShare: debtConfigShare.startValue,
      incrementRateShare: debtConfigShare.incrementRate,
      population,
      startDebt,
      endDebt,
      startDate,
      endDate,
      ads,
    });
  }

  useEffect(() => {
    const updateConfig = setInterval(() => {
      setDebtConfig();
    }, UPDATE_INTERVAL);
    return () => {
      clearInterval(updateConfig);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtConfigState]);

  useEffect(() => {
    if (
      !debtConfigState.population &&
      !debtConfigState.startDebt &&
      !debtConfigState.endDebt &&
      !debtConfigState.startDate &&
      !debtConfigState.endDate &&
      !debtConfigState.debtFederal &&
      !debtConfigState.debtShare &&
      !debtConfigState.incrementRateFederal &&
      !debtConfigState.incrementRateShare &&
      !debtConfigState.ads
    ) {
      setDebtConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtConfigState]);

  strings.setLanguage(language);
  return (
    <React.Fragment>
      <div data-testid="debt-clock" className="main-container">
        <div className="debt-clock-federal-container">
          <h1
            data-testid="debt-clock-federal-text"
            className={classNames(
              'debt-clock-federal-text',
              `debt-clock-federal-text-${language}`,
              `provincial-${language}-${province}`,
            )}
          >
            {ProvinceEnum.includes(province)
              ? strings.provinces[province].toUpperCase()
              : strings.debtClock.debtClockFederal.toUpperCase()}
          </h1>
          {debtConfigState.debtFederal &&
            debtConfigState.incrementRateFederal &&
            debtConfigState.startDebt &&
            debtConfigState.endDebt && (
              <DebtClockCSS
                className={classNames(
                  'ticker-federal',
                  `ticker-provincial-${province}`,
                )}
                interval={federalInterval}
                initialDebt={debtConfigState.debtFederal}
                incrementRate={debtConfigState.incrementRateFederal}
                isFederal
                slideUp={debtConfigState.endDebt > debtConfigState.startDebt}
              />
            )}
        </div>
        <div className="debt-clock-share-taxpayer-logo-container">
          <div className="taxpayer-logo">
            <img
              src={selectLogo(language)}
              alt="tax payer logo"
              className="logo-width"
            />
          </div>
          <div className="debt-clock-share">
            <h1
              data-testid="debt-clock-share-text"
              className={classNames(
                `debt-clock-share-text`,
                `debt-clock-share-text-${language}`,
              )}
            >
              {strings.debtClock.debtClockShare.toUpperCase()}
            </h1>
            {debtConfigState.debtShare &&
              debtConfigState.incrementRateShare !== null &&
              debtConfigState.incrementRateShare !== undefined &&
              debtConfigState.startDebt &&
              debtConfigState.endDebt && (
                <DebtClockCSS
                  interval={shareInterval}
                  initialDebt={debtConfigState.debtShare}
                  incrementRate={debtConfigState.incrementRateShare}
                  isFederal={false}
                  slideUp={debtConfigState.endDebt > debtConfigState.startDebt}
                />
              )}
          </div>
        </div>
        <div className="sign-petition-text-container">
          <span data-testid="sign-petition-text" className="sign-petition-text">
            {strings.debtClock.signPetitionNotBold}
            <span className="sign-petition-bold">
              {strings.debtClock.signPetitionBold}
            </span>
          </span>
        </div>
      </div>
      <div className="supported-by-container">
        <h1
          data-testid="supported-by-text"
          className={classNames(
            'supported-by-text',
            `supported-by-text-${language}`,
          )}
        >
          {strings.debtClock.supportedBy.toUpperCase()}
        </h1>
        {debtConfigState.ads && <SlidingAds ads={debtConfigState.ads} />}
      </div>
    </React.Fragment>
  );
}

export default Home;
