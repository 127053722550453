import React from 'react';
import classNames from 'classnames';
import './DebtClockCSS.css';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import roundTo from 'round-to';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function setupNumber(num, incrementRate, interval, isFederal, slideUp) {
  const elements = [];
  const withCommas = numberWithCommas(parseFloat(num).toFixed(2));
  const noCommasLength = num.toString().length;
  let debtRounded = roundTo.down(
    num,
    -(roundTo.down(num, 0).toString().length - 1),
  );
  if (debtRounded.toString().charAt(0) !== '1') {
    debtRounded = `${1}${debtRounded.toString().substring(1)}`;
  }
  const digitsAnimatedThroughInOneAnimationCycle = 10;

  let animationDuration =
    (Number(debtRounded) / Math.abs(incrementRate)) *
    digitsAnimatedThroughInOneAnimationCycle *
    (interval / 1000);

  const numbersInBetweenMap = {
    0: '1 2 3 4 5 6 7 8 9',
    1: '2 3 4 5 6 7 8 9 0',
    2: '3 4 5 6 7 8 9 0 1',
    3: '4 5 6 7 8 9 0 1 2',
    4: '5 6 7 8 9 0 1 2 3',
    5: '6 7 8 9 0 1 2 3 4',
    6: '7 8 9 0 1 2 3 4 5',
    7: '8 9 0 1 2 3 4 5 6',
    8: '9 0 1 2 3 4 5 6 7',
    9: '0 1 2 3 4 5 6 7 8',
  };
  elements.push(
    <div key={uuidv4()} className="dollar-sign-container">
      <div className="dollar-sign">$</div>
    </div>,
  );
  let digitPlace = 0;
  for (let i = 0; i < withCommas.length; i += 1) {
    let newElementToAdd;
    const key = uuidv4();
    if (Number.isNaN(Number.parseInt(withCommas.charAt(i), 10))) {
      newElementToAdd = (
        <span key={key} className="non-digit">
          {withCommas.charAt(i)}
        </span>
      );
    } else {
      let clssName;
      if (slideUp) {
        if (
          digitPlace < noCommasLength - incrementRate.toString().length ||
          !isFederal
        ) {
          clssName = 'digit-container-animation-pause';
        } else {
          clssName = 'digit-container';
        }
      }
      if (!slideUp) {
        if (
          digitPlace < noCommasLength - incrementRate.toString().length ||
          !isFederal
        ) {
          clssName = 'digit-container-animation-pause-slide-down';
        } else {
          clssName = 'digit-container-slide-down';
        }
      }
      newElementToAdd = (
        <div key={key} className="digit">
          <div
            className={clssName}
            style={{
              animationDuration: `${animationDuration}s`,
            }}
          >
            {withCommas.charAt(i)} {numbersInBetweenMap[withCommas.charAt(i)]}{' '}
            {withCommas.charAt(i)}
          </div>
        </div>
      );
      digitPlace += 1;
      if (animationDuration < 7) {
        animationDuration /= 1.4;
      } else {
        animationDuration /= 10;
      }
    }
    elements.push(newElementToAdd);
  }
  return elements;
}

function DebtClockCSS({
  className,
  initialDebt,
  incrementRate,
  interval,
  isFederal,
  slideUp,
}) {
  const startDebt = setupNumber(
    initialDebt,
    incrementRate,
    interval,
    isFederal,
    slideUp,
  );
  return (
    <div className={classNames('debt-clock-container', className)}>
      {startDebt}
    </div>
  );
}

DebtClockCSS.propTypes = {
  className: PropTypes.string,
  initialDebt: PropTypes.number.isRequired,
  incrementRate: PropTypes.number.isRequired,
  interval: PropTypes.number.isRequired,
  isFederal: PropTypes.bool.isRequired,
  slideUp: PropTypes.bool.isRequired,
};

DebtClockCSS.defaultProps = {
  className: null,
};
export default DebtClockCSS;
