const backup = {
  // provincial debt numbers obtained from debtclock.ca
  debt: {
    national: {
      startDebt: 1048700000000,
      endDebt: 1191600000000,
    },
    bc: {
      startDebt: 93489000000,
      endDebt: 107924000000,
    },
    ab: {
      startDebt: 97199000000,
      endDebt: 95952000000,
    },
    sk: {
      startDebt: 18490000000,
      endDebt: 18080000000,
    },
    mb: {
      startDebt: 29415000000,
      endDebt: 31057000000,
    },
    on: {
      startDebt: 395785000000,
      endDebt: 406411000000,
    },
    qc: {
      startDebt: 215736000000,
      endDebt: 232077000000,
    },
    nb: {
      startDebt: 11613000000,
      endDebt: 11796000000,
    },
    nl: {
      startDebt: 15699448000,
      endDebt: 16226174000,
    },
    ns: {
      startDebt: 18186000000,
      endDebt: 19544000000,
    },
    pe: {
      startDebt: 2515400000,
      endDebt: 2800300000,
    },
  },
  // new population numbers obtained from Stats Canada Q2 2023, last updated Jul 2023
  population: {
    national: 39858480,
    bc: 5437722,
    ab: 4703772,
    sk: 1221439,
    mb: 1444190,
    on: 15500632,
    qc: 8831257,
    nb: 831618,
    ns: 1047232,
    nl: 533710,
    pe: 176113,
  },
  startDate: '04/01/2021',
  endDate: '03/31/2022',
  ads: [
    'Scott Hennig',
    'Mainland Construction Ltd., Kinuso, AB',
    'Derek James - Osprey Inspections Ltd, Castor Alberta ',
    'Sean Wenzel, Norwood, Ontario',
    'NRG Holdings Ltd, Vernon BC',
    'Allan R Rosengren, Orleans ON',
    'Christian Backenforth, Oshawa, ON',
    'Midnight Sun Drilling Inc.',
    'Tammy Watson & Shane Clowers, Vancouver, BC',
    'C & B International Freight System Inc., Sarnia, Ontario',
    'Bill Tregenza, Marten River On.',
    'BLAIR HENRY, LACOMBE COUNTY',
    'Jeff Allan and Theresa Voigt, Cochrane, AB',
    'Donal & Gloria Wolff, Sherwood Park, AB',
    'Kim Brooks, Ottawa',
    'PHIL LAMBERT, Edmonton Alberta',
    'PHIL LAMBERT, Edmonton Alberta',
    'Richard & Brenda Lee Lueke, Regina, SK',
    'Eric Jacobsen, North Vancouver BC',
    'Walter & Joan Shemly',
    'Dornan Properties , EDMONTON',
    'Tim Clifford, Lucky Lake, SK',
    'Mathew Hanke, Beechy, SK',
    'Tall Timber Construction, Spruce Grove, AB',
    'Raymond Gaumont, Fort Saskatchewan, AB',
    "Doc's Truck & AG Repair Ltd. - Dallas Baumann, Cudworth, SK",
    "Alf's Roofing Ltd, Edmonton, AB",
    'Guy Blanchette, Morinville, AB',
    'Max Fuel Distributors Ltd. - Ingo Von Wackerbarth, Slave Lake, AB',
    'Jaymar Hauling (88) Ltd. - Jake & Annette Braun, Slave Lake, AB',
    'Conrad & Jo Ann Gaumont, Fort Saskatchewan, AB',
    'Michael Hankirk, Fort Saskatchewan, AB',
    'Kinuso Mercantile, Kinuso, AB',
    'Judi and Finn Jensen, Oliver, BC',
    'Leon Harder, Red Deer, AB',
    'Brian Pidcock, Vancouver, BC',
    'Dragon Industrial Services',
    'Sturgeon Valley Fertilizers, St. Albert, AB',
    'Morley & Jan Gabert, Fort Saskatchewan, AB',
    'The Hennig Family, Fort Saskatchewan, AB',
    'Shannon Morrison, Regina, SK',
    'PHIL LAMBERT, Edmonton Alberta',
    'Freson Bros., Stony Plain, AB',
    'R.B Williams Industrial Supply Ltd., wants phone number on display **',
    'Stew and Judy Valcour, New Brunswick',
    'Grove Collision, Spruce Grove, AB',
    'Theo & Reinata Feitsma, Dapp, AB',
    'Jim & Belva Martin, Laporte, SK',
    'Cobra Directional Drilling & Trenching, Wants business & Phone number on debt clock',
    'Progressive Completions Ltd.',
    'Kyle & Terri Herzog, Vermilion, AB',
    'Southfort Farms / Shaun & Linda Galloway, Fort Saskatchewan, AB',
    'Total Plumbing & Heating, Westlock, AB',
    'Jerome & Garnet Nunweiler, Eatonia, SK',
    'Rapp Turner Farms Ltd / Monty & Lane McGrath, Eatonia, SK',
    "Craig's Welding Ltd, Morinville, AB",
    'Ken Fehr',
    'Allan & Trudy Fawell, Unity, SK',
    'A.B Hollingworth & Sons, Valleyview, AB',
    'Century 21 Northern Reality, Jim Sparks, Slave Lake, AB',
    'Reid & Brenda Findlay, Lloydminster, SK',
    'Neekinan Farms, Laporte, SK',
    'Lorne & Linda Scholer, Luseland, SK',
    'Better View Farms, Arthur Wever, Lloydminster, SK',
    'Cross Seed Farm, Westlock, AB',
    'Myshak Sales & Rentals Ltd., Acheson, AB',
    'NSC Farms Ltd., Lashburn, SK',
    'Hitch House Ltd.',
    'Academy Petroleum Industries',
    'Jiricka Farms Ltd., Biggar, SK',
    'Mach 1 Diesel Repair, Lloydminster, AB',
    'Scott Musgrave, Lloydminster, AB',
    'Mike Henriksen',
    'Superior Trenching',
    'CRC Wellhead Supply Co. Ltd.',
    'Lehmann Plumbing Ltd.',
    'Russell NDE Systems',
    'Revlyn Demolition & Recycling',
    'Burke Interior Systems Ltd.',
    'Gateway Power Sports',
    'Flashpoint Electric Inc., Bonnyville, AB',
    'Kam Mechanical',
    'Lorenzo Donini',
    'River Hill Speckle Park, Barry & Cary Ducherer, Nielburg, SK',
    'Daysland Pharmacy',
    'Castor Value Drug Mart, Randy Kary',
    'Athabasca Auto Ltd / Boyle Auto and Industries, Alta',
    'IR Supplies & Services, Rob Muschket, Edmonton, AB',
    'Zeebest Plastics Of Canada Inc., Morinville, AB',
    'Universe Machine Corporation, Edmonton, AB',
    'Eldon Galloway, Crosswinds Driving Range Fort Park RV Storage, Fort Saskatchewan, AB',
    'Barry & Joanne Elliott',
    'Glenn & Colleen Bauer, Edmonton, AB',
    'North West Equipment Ltd., Calgary/Edmonton, AB',
    'Peter Haarsma, Spruce Grove, AB',
    'Westlock Veterinary Center, Westlock, AB',
    'Gil Dubrule, CPA, Westlock, AB',
    'Blacklaw Manufacturing Inc.',
    'B & A Petroleum Ltd.',
    'Bagshaw Electric Ltd., Stettler, AB ',
    'Dynaline Industries Inc.',
    'Bouma Meats Ltd, Provost, AB',
    "Flying Circle Ranch, The Hunt's, Endiang, AB",
    'Devon & Gail Boulding, Stettler, AB ',
    'Pugh Farms, Edgerton, AB',
    'In Memory of William Mulhbach, Stettler, AB',
    'Elmera, Caroline & Vern Lohner - Silverdale Farm, Daysland, AB',
    'In Memory of Harold & Joan Lepard, Erskine, AB',
    'Ted & Diane Draeger, Wetaskiwin, AB',
    'Paul & Marilyn Norton, Castor, AB',
    "John's Shopmobile, Camrose, AB",
    'Alder Ridge Excavating Ltd., High Prairie, AB',
    'Dzivinski Farms, Westlock County, AB ',
    'Miller Hill Farms, Lionel & Dianne Miller',
    'Farness Farms Inc., Ohaton, AB',
    'Ted & Orlene Wigglesworth, Camrose, AB',
    'Don & Kim Hartle, Lake Lenore, SK',
    'Stampede Glass Ltd.',
    'Denis & Joanne Savoie, Swan Hills, AB',
    'Carmen & Guye Lappin, White Court, AB',
    'Pawsey Farm, Edgerton, AB',
    'Marion De Jongh, Camrose, AB',
    'Wildor Farms Ltd., The Seigers, Coronation, AB',
    'Penny Stephenson',
    'Lazy E. Bar Ranching Ltd. , Bashaw, AB',
    'Canadian Horizontal Drilling, Wetaskiwin, AB',
    'Lanko Glass Ltd., Wetaskiwin, AB',
    'Blades Farms, Ohaton, AB',
    'Ed & Pat Rostaing, Bawlf, AB',
    'Sydney Welding Ltd., Millet, AB',
    'TJ Freadrich Farms Ltd., Killam, AB',
    'R.J. Hoffman Holdings Ltd., Lloydminster, AB',
    'Don & Pam Beamish, Lloydminster, SK ',
  ],
  supportedByImages: [],
};

export default backup;
