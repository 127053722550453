import axios from 'axios';
import { CONFIG_URL } from 'config/constants';
import backup from 'config/backup';
import TaxPayerLogoEnglish from 'assets/TaxPayerLogoEnglish.svg';
import TaxPayerLogoFrench from 'assets/TaxPayerLogoFrench.svg';

export async function getConfig() {
  try {
    const response = await axios.get(CONFIG_URL);
    const retData = response?.data ? response.data : backup;
    return retData;
  } catch (error) {
    return backup;
  }
}

export function calculateFederalStartValueAndIncrementRate(
  startDebt,
  endDebt,
  startDateString,
  endDateString,
  intervalInMilliSeconds,
) {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const currentDay = new Date();

  const secondsPassedSinceStartDate = Math.abs(currentDay - startDate) / 1000;
  const secondsBetweenEndStartDate = Math.abs(
    Math.abs(endDate - startDate) / 1000,
  );

  const incrementRatePerSecond =
    (endDebt - startDebt) / secondsBetweenEndStartDate;
  const startValue = adjustValueIfZeroInSecondDecimalSpot(
    startDebt + incrementRatePerSecond * secondsPassedSinceStartDate,
  );
  const incrementRatePerInterval =
    incrementRatePerSecond * (intervalInMilliSeconds / 1000);

  return {
    startValue: parseFloat(startValue),
    incrementRate: parseFloat(incrementRatePerInterval.toFixed(4)),
  };
}

export function calculateShareStartValueAndIncrementRate(
  startDebt,
  endDebt,
  startDateString,
  endDateString,
  intervalInMilliSeconds,
  population,
) {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const currentDay = new Date();

  const secondsPassedSinceStartDate = Math.abs(currentDay - startDate) / 1000;
  const secondsBetweenEndStartDate = Math.abs(
    Math.abs(endDate - startDate) / 1000,
  );

  const debtClockFederalIncrementRate =
    (endDebt - startDebt) / secondsBetweenEndStartDate;

  const debtClockFederalStartValue =
    startDebt + debtClockFederalIncrementRate * secondsPassedSinceStartDate;

  const debtShareStart = startDebt / population;
  const debtShareCurrent = adjustValueIfZeroInSecondDecimalSpot(
    debtClockFederalStartValue / population,
  );
  const debtShareFinal = endDebt / population;
  const incrementRatePerSecond =
    (debtShareFinal - debtShareStart) / secondsBetweenEndStartDate;

  const incrementRatePerInterval =
    incrementRatePerSecond * (intervalInMilliSeconds / 1000);

  return {
    startValue: parseFloat(debtShareCurrent),
    incrementRate: parseFloat(incrementRatePerInterval.toFixed(4)),
  };
}

export function adjustValueIfZeroInSecondDecimalSpot(value) {
  const valueTwoDecimalSpots = value.toFixed(2);
  let retVal = valueTwoDecimalSpots;
  if (valueTwoDecimalSpots.charAt(valueTwoDecimalSpots.length - 1) === '0') {
    retVal = parseFloat(valueTwoDecimalSpots) + 0.01;
  }
  return parseFloat(retVal);
}

export function selectLogo(language) {
  const mapLanguageLogos = {
    en: TaxPayerLogoEnglish,
    fr: TaxPayerLogoFrench,
    cn: TaxPayerLogoEnglish,
    cn_yue: TaxPayerLogoEnglish,
    tl: TaxPayerLogoEnglish,
    pa: TaxPayerLogoEnglish,
  };
  return mapLanguageLogos[language];
}

export const ProvinceEnum = [
  'bc',
  'ab',
  'sk',
  'mb',
  'on',
  'qc',
  'nb',
  'ns',
  'nl',
  'pe',
];

export function provincialDisplay(province) {
  if (province !== undefined && ProvinceEnum.includes(province)) {
    return true;
  }
  return false;
}
