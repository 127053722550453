import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import './SlidingAds.css';

const SlidingAds = ({ ads }) => {
  return (
    <div data-testid="sliding-ads" className="container">
      {ads?.length > 0 && (
        <div
          className="ad-slider"
          style={{
            animation: `${
              ads.length * 4
            }s linear 0s infinite normal none running slide`,
          }}
        >
          {ads.map(ad => {
            const key = uuidv4();
            return (
              <div data-test-id="ad" key={key} className="center">
                <p className="ad-text">{ad}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

SlidingAds.propTypes = {
  ads: PropTypes.arrayOf(String).isRequired,
};

export default SlidingAds;
